import React, { useEffect, useRef } from 'react';
import Person1 from './assets/testimonios_img/person1.jpg';
import Person2 from './assets/testimonios_img/person2.jpg';
import Person3 from './assets/testimonios_img/person3.jpg';
import Person4 from './assets/testimonios_img/person4.jpg';
import Person5 from './assets/testimonios_img/persona5.jpg';
import Person6 from './assets/testimonios_img/persona6.jpg';
import Person7 from './assets/testimonios_img/persona7.jpg';
import Person8 from './assets/testimonios_img/persona8.jpg';
import Person9 from './assets/testimonios_img/persona9.jpg'; // Nuevos testimonios
import Person10 from './assets/testimonios_img/persona10.jpg'; // Nuevos testimonios

function Testimonials() {
  const scrollContainerRef = useRef(null);

  // Función para hacer scroll automático
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    let scrollAmount = 0;
    const scrollStep = 2; // Velocidad del scroll
    const maxScroll = scrollContainer.scrollWidth - scrollContainer.clientWidth;

    const scrollInterval = setInterval(() => {
      scrollAmount += scrollStep;
      if (scrollAmount >= maxScroll) {
        scrollAmount = 0; // Reiniciar el scroll al principio
      }
      scrollContainer.scrollTo({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }, 50); // Velocidad en milisegundos entre cada paso del scroll

    return () => clearInterval(scrollInterval);
  }, []);

  return (
    <section id="testimonios" className="bg-white py-12 md:py-24 lg:py-32">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold" style={{ color: 'var(--accent-color)' }}>
          Lo que dicen nuestros clientes
        </h2>
        <p className="mt-4 text-lg" style={{ color: '#000000' }}>
          Descubre cómo hemos ayudado a otros a alcanzar sus objetivos
        </p>

        {/* Contenedor para el efecto del tren */}
        <div className="overflow-x-auto" ref={scrollContainerRef}>
          <div className="flex space-x-6 lg:space-x-8 mt-8" style={{ minWidth: '100%' }}>
            {/* Testimonios */}
            <div className="min-w-[300px] bg-gray-100 p-6 rounded shadow">
              <p className="text-black">
                "El equipo de The 18s Technologies hizo un excelente trabajo en el desarrollo de nuestro sitio web. Estamos muy satisfechos con el resultado final y la atención personalizada que recibimos durante todo el proceso."
              </p>
              <div className="mt-4 flex items-center space-x-4">
                <img src={Person1} alt="Cliente 1" className="w-12 h-12 rounded-full" />
                <div>
                  <p className="font-bold">John Doe</p>
                  <p className="text-gray-600">CEO, Empresa ABC</p>
                </div>
              </div>
            </div>
            <div className="min-w-[300px] bg-gray-100 p-6 rounded shadow">
              <p className="text-black">
                "Trabajar con The 18s Technologies ha sido una experiencia increíble. Su equipo de expertos nos guió a lo largo de todo el proceso y el resultado final superó nuestras expectativas."
              </p>
              <div className="mt-4 flex items-center space-x-4">
                <img src={Person2} alt="Cliente 2" className="w-12 h-12 rounded-full" />
                <div>
                  <p className="font-bold">Sarah Miller</p>
                  <p className="text-gray-600">Gerente de Marketing, Empresa XYZ</p>
                </div>
              </div>
            </div>
            <div className="min-w-[300px] bg-gray-100 p-6 rounded shadow">
              <p className="text-black">
                "The 18s Technologies nos ayudó a mejorar nuestra presencia en línea de una manera que no creíamos posible. Su profesionalismo y dedicación son inigualables."
              </p>
              <div className="mt-4 flex items-center space-x-4">
                <img src={Person3} alt="Cliente 3" className="w-12 h-12 rounded-full" />
                <div>
                  <p className="font-bold">Carlos Pérez</p>
                  <p className="text-gray-600">Director, Empresa DEF</p>
                </div>
              </div>
            </div>
            <div className="min-w-[300px] bg-gray-100 p-6 rounded shadow">
              <p className="text-black">
                "Gracias a The 18s Technologies, hemos visto un aumento significativo en el tráfico de nuestro sitio web. ¡Altamente recomendados!"
              </p>
              <div className="mt-4 flex items-center space-x-4">
                <img src={Person4} alt="Cliente 4" className="w-12 h-12 rounded-full" />
                <div>
                  <p className="font-bold">Laura Gómez</p>
                  <p className="text-gray-600">Fundadora, Startup GHI</p>
                </div>
              </div>
            </div>
            <div className="min-w-[300px] bg-gray-100 p-6 rounded shadow">
              <p className="text-black">
                "El equipo de The 18s Technologies ha demostrado ser increíblemente profesional y eficiente. No podríamos estar más satisfechos."
              </p>
              <div className="mt-4 flex items-center space-x-4">
                <img src={Person5} alt="Cliente 5" className="w-12 h-12 rounded-full" />
                <div>
                  <p className="font-bold">Elena</p>
                  <p className="text-gray-600">CTO, Empresa JKL</p>
                </div>
              </div>
            </div>
            <div className="min-w-[300px] bg-gray-100 p-6 rounded shadow">
              <p className="text-black">
                "Nos encantó trabajar con The 18s Technologies. Su enfoque personalizado realmente marcó la diferencia en nuestra estrategia digital."
              </p>
              <div className="mt-4 flex items-center space-x-4">
                <img src={Person6} alt="Cliente 6" className="w-12 h-12 rounded-full" />
                <div>
                  <p className="font-bold">Ana Torres</p>
                  <p className="text-gray-600">CMO, Empresa MNO</p>
                </div>
              </div>
            </div>
            <div className="min-w-[300px] bg-gray-100 p-6 rounded shadow">
              <p className="text-black">
                "The 18s Technologies transformó completamente nuestra presencia online, llevándonos a niveles que no habíamos imaginado."
              </p>
              <div className="mt-4 flex items-center space-x-4">
                <img src={Person7} alt="Cliente 7" className="w-12 h-12 rounded-full" />
                <div>
                  <p className="font-bold">Jorge Martínez</p>
                  <p className="text-gray-600">Fundador, Empresa PQR</p>
                </div>
              </div>
            </div>
            <div className="min-w-[300px] bg-gray-100 p-6 rounded shadow">
              <p className="text-black">
                "Recomiendo a The 18s Technologies sin dudarlo. El equipo es excepcional y entregan resultados sobresalientes."
              </p>
              <div className="mt-4 flex items-center space-x-4">
                <img src={Person8} alt="Cliente 8" className="w-12 h-12 rounded-full" />
                <div>
                  <p className="font-bold">Valeria López</p>
                  <p className="text-gray-600">CEO, Empresa STU</p>
                </div>
              </div>
            </div>
            {/* Testimonios adicionales */}
            <div className="min-w-[300px] bg-gray-100 p-6 rounded shadow">
              <p className="text-black">
                "La colaboración con The 18s Technologies fue esencial para el éxito de nuestro negocio digital. ¡100% recomendados!"
              </p>
              <div className="mt-4 flex items-center space-x-4">
                <img src={Person9} alt="Cliente 9" className="w-12 h-12 rounded-full" />
                <div>
                  <p className="font-bold">Fernando López</p>
                  <p className="text-gray-600">CFO, Empresa XYZ</p>
                </div>
              </div>
            </div>
            <div className="min-w-[300px] bg-gray-100 p-6 rounded shadow">
              <p className="text-black">
                "The 18s Technologies superó todas nuestras expectativas. Nos ayudaron a incrementar el tráfico y las ventas significativamente."
              </p>
              <div className="mt-4 flex items-center space-x-4">
                <img src={Person10} alt="Cliente 10" className="w-12 h-12 rounded-full" />
                <div>
                  <p className="font-bold">Sofía Pérez</p>
                  <p className="text-gray-600">CEO, Empresa XYZ</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
