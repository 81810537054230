import React from 'react';
import './Footer.css'; // Importa los estilos específicos de Footer

function Footer() {
  return (
    <footer className="footer-container">
      <p>&copy; 2024 The 18s Techonologies. Todos los derechos reservados.</p>
      <p>Dirección: Estados Unidos </p>
      <p>Teléfono: +1 562 291 8461</p>
      <p>Email: customerservice@18s.tech.</p>
    </footer>
  );
}

export default Footer;
