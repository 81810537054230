import React from 'react';
import './Home.css';
import TypingEffect from './TypingEffect';

function Home() {
  return (
    <section id="inicio" className="video-section text-secondary-color py-6 md:py-12 lg:py-16">
      <video autoPlay loop muted className="background-video">
        <source src="/video/video_encabezado.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="video-content container mx-auto px-4 text-center">
        <h2 className="highlighted-text pulsating-text">¡Te estábamos esperando!</h2>
        <h1 className="text-4xl font-bold move-up">
          <TypingEffect
            texts={[
              "Transforma tu presencia en línea con nosotros",
              "Creamos páginas web personalizadas para empresas públicas y privadas"
            ]}
            speed={50}
            delayBetweenTexts={3000}
            delayBeforeRestart={4000}
          />
        </h1>
        <a href="#contacto" className="btn mt-6 rounded-lg bouncing-button">
          Solicita una consulta gratuita
        </a>
      </div>
    </section>
  );
}

export default Home;
