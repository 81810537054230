import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <section className="contact-section">
      <h2 className="contact-title">¿LISTO PARA LLEVAR TU SITIO WEB AL SIGUIENTE NIVEL?</h2>
      <p>Escríbenos hoy y transforma tu presencia digital:</p>
      <div className="contact-container">
        <div className="contact-options">
          <a href="https://wa.me/15622918461" className="contact-option whatsapp">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="icon">
              <path d="M380.9 97.1c-34.7-34.6-81-53.7-130.1-53.7-102 0-184.9 82.9-184.9 184.9 0 32.7 8.5 64.6 24.6 93L72 439l104.3-27.3c27.8 15 59.1 23 91.5 23 102 0 184.9-82.9 184.9-184.9 0-49-19.2-95.3-53.8-130zm-130.1 328c-27.5 0-54.5-7.4-78.2-21.3l-5.6-3.3-61.8 16.2 16.3-61.3-3.4-5.8c-13.7-23.4-21-50.2-21-77.8 0-88.3 71.8-160.1 160.1-160.1 42.7 0 82.8 16.6 112.9 46.8 30.1 30.2 46.8 70.2 46.8 112.9 0 88.3-71.8 160.2-160.1 160.2zm82.2-113.3l-30.2-8.5c-4.1-1.2-8.5-0.4-11.6 2.6l-14.2 14.3c-15.1-7.9-29.3-17.8-41.8-29.3-12.5-12.6-23.1-26.7-31.1-41.7l14.3-14.4c3-3 3.9-7.4 2.6-11.6l-8.5-30.3c-1.2-4.1-4.8-7-8.9-7h-32.6c-5 0-9.3 4-9.3 8.9 0 67.1 54.5 121.6 121.6 121.6 4.9 0 8.9-4.3 8.9-9.3v-32.5c0-4.2-2.9-7.7-7-8.9z"/>
            </svg>
            Envíanos un mensaje por WhatsApp
          </a>

          <a href="https://docs.google.com/forms/d/e/1FAIpQLSe93MPfA3ou3iiCOkyfOd-hfowl03HjPiakY35314eYaXxs3g/viewform?usp=sf_link" className="contact-option formulario">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon">
              <path d="M96 0C60.7 0 32 28.7 32 64l0 384c0 35.3 28.7 64 64 64l288 0c35.3 0 64-28.7 64-64l0-384c0-35.3-28.7-64-64-64L96 0zM208 288l64 0c44.2 0 80 35.8 80 80c0 8.8-7.2 16-16 16l-192 0c-8.8 0-16-7.2-16-16c0-44.2 35.8-80 80-80zm-32-96a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM512 80c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 64c0 8.8 7.2 16 16 16s16-7.2 16-16l0-64zM496 192c-8.8 0-16 7.2-16 16l0 64c0 8.8 7.2 16 16 16s16-7.2 16-16l0-64c0-8.8-7.2-16-16-16zm16 144c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 64c0 8.8 7.2 16 16 16s16-7.2 16-16l0-64z"/>
            </svg>
            Llena nuestro formulario
          </a>
          
          <a href="https://www.facebook.com/profile.php?id=61564820045835&mibextid=ZbWKwL" className="contact-option facebook">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="icon">
              <path d="M279.14 288l14.22-92.66h-88.91V150.33c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S251.25 0 216.49 0c-73.5 0-121.36 44.83-121.36 125.3v70.04H22.89V288h72.24v224h89.5V288z"/>
            </svg>
            Contáctanos por Facebook
          </a>
          
          <a href="https://www.instagram.com/the18stech" className="contact-option instagram">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="icon">
              <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
            </svg>
            Síguenos en Instagram
          </a>

          <a href="tel:+1 562 291 8461" className="contact-option telefono">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon">
              <path d="M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z"/>
            </svg>
            Llámanos: +1 562 291 8461
          </a>
        </div>
      </div>
    </section>
  );
}

export default Contact;
