import React from 'react';
import './About.css'; // Importa los estilos específicos de About

import directorImage from './assets/nostros/Director.png';
import relacionesImage from './assets/nostros/Relaciones_Exteriores.jpeg';
import marketingImage from './assets/nostros/Marketing.jpeg';

function About() {
  return (
    <section id="sobre-nosotros" className="about-section">
      <h2>Sobre Nosotros</h2>

      {/* Grid de Perfiles */}
      <div className="team-grid">
        <div className="about-item team-member">
          <img src={directorImage} alt="Director" className="team-image" />
          <div className="team-info">
            <h3>Manuel Rios</h3>
            <p>Ingeniero en Sistemas - Director y CEO</p>
            <div className="team-description">
              <p>Director con más de 6 años de experiencia en desarrollo web y SEO.</p>
            </div>
          </div>
        </div>

        <div className="about-item team-member">
          <img src={relacionesImage} alt="Relaciones Exteriores" className="team-image" />
          <div className="team-info">
            <h3>Jose Salazar</h3>
            <p>Doctor en Relaciones Exteriores - Encargado de Relaciones Exteriores</p>
            <div className="team-description">
              <p>Especialista en la gestión de relaciones empresariales y comerciales internacionales con 15 años de experiencia.</p>
            </div>
          </div>
        </div>

        <div className="about-item team-member">
          <img src={marketingImage} alt="Marketing" className="team-image" />
          <div className="team-info">
            <h3>Lina Rodríguez</h3>
            <p>Doctora en Marketing - Encargada de Marketing</p>
            <div className="team-description">
              <p>Lina tiene un doctorado en Marketing y más de 7 años de experiencia en la creación de estrategias de marketing digital.</p>
            </div>
          </div>
        </div>

        <div className="about-item team-member">
          <div className="team-info">
            <h3>Zabdiel</h3>
            <p>Ingeniero en Sistemas - Líder de Proyectos</p>
            <div className="team-description">
              <p>Encargado de supervisar proyectos y asegurar su cumplimiento con los más altos estándares.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Grid de Información */}
      <div className="about-info-grid">
        <div className="about-item">
          <h3>Nuestra Misión</h3>
          <p>
            Proporcionar soluciones web innovadoras y de alta calidad que ayuden a nuestros clientes a alcanzar sus metas.
          </p>
        </div>

        <div className="about-item">
          <h3>Visión</h3>
          <p>
            Ser líderes en el desarrollo de soluciones tecnológicas que transformen el entorno digital.
          </p>
        </div>

        <div className="about-item">
          <h3>Nuestros Valores</h3>
          <p>
            Innovación, calidad, compromiso y responsabilidad son los pilares que nos guían.
          </p>
        </div>

        <div className="about-item">
          <h3>Nuestro Equipo</h3>
          <p>
            Contamos con un equipo multidisciplinario de expertos en desarrollo web, diseño gráfico, marketing digital y optimización SEO.
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;
