import React, { useState } from 'react';
import './Portfolio.css'; // Importa los estilos específicos de Portfolio

import projectImage7 from './assets/imagenes_proyectos/project7.png'; // Proyecto 7
import projectImage8 from './assets/imagenes_proyectos/project8.png'; // Proyecto 8

function Portfolio() {
  const [hoverMessage, setHoverMessage] = useState('');

  const handleMouseEnter = () => {
    setHoverMessage(`Clic para ver >>`);
  };

  const handleMouseLeave = () => {
    setHoverMessage('');
  };

  const handleClick = (link) => {
    window.open(link, '_blank');
  };

  return (
    <section id="portafolio" className="portfolio-section">
      <h2>Nuestro Portafolio</h2>
      <div className="portfolio-grid">
        <div
          className="portfolio-item"
          onClick={() => handleClick('https://miriamreyes.site')}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="hover-message">{hoverMessage}</div>
          <img src={projectImage7} alt="Proyecto 7" className="portfolio-image" />
        </div>
        <div
          className="portfolio-item"
          onClick={() => handleClick('https://monroystrucking.com')}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="hover-message">{hoverMessage}</div>
          <img src={projectImage8} alt="Proyecto 8" className="portfolio-image" />
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
