import React, { useState } from 'react';
import './Benefits.css'; // Importar estilos personalizados
import Lottie from 'react-lottie-player';
import animationData from './assets/animations/animation.json'; // Importa tu archivo animation.json

function Benefits() {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const benefits = [
    {
      title: "Soluciones a la medida:",
      description: "Diseñamos y desarrollamos plataformas personalizadas.",
      extraInfo: "Adaptamos nuestras soluciones a las necesidades específicas de tu negocio."
    },
    {
      title: "Experiencia en UX/UI:",
      description: "Interfaces intuitivas y atractivas para mejorar la experiencia del usuario.",
      extraInfo: "Creamos diseños centrados en el usuario para maximizar la satisfacción."
    },
    {
      title: "Posicionamiento SEO:",
      description: "Mejora continua para que tu sitio esté bien posicionado en los motores de búsqueda.",
      extraInfo: "Optimización avanzada para garantizar un alto rendimiento en los buscadores."
    },
    {
      title: "Velocidad y rendimiento:",
      description: "Sitios rápidos, seguros y funcionales en todo momento.",
      extraInfo: "Garantizamos tiempos de carga rápidos y una experiencia sin interrupciones."
    },
    {
      title: "Servicio Profesional y personalizado:",
      description: "Acompañamiento de los mejores profesionales y especialistas en desarrollo.",
      extraInfo: "Contamos con un equipo de expertos listos para atender cualquier necesidad."
    },
  ];

  const handleMouseEnter = (index) => {
    setHoveredIndex(index); // Mostrar información extra cuando se pasa el ratón
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null); // Ocultar información extra cuando el ratón sale
  };

  return (
    <section id="beneficios" className="benefits-section">
      <div className="container mx-auto px-4 text-center">
        <h2 className="benefits-title">Nuestros Beneficios</h2>
        <div className="benefits-grid">
          <div className="benefits-image">
            <div className="image-frame">
              {/* Integración de la animación de Lottie */}
              <Lottie
                loop
                animationData={animationData}
                play
                className="animated-image"
              />
            </div>
          </div>
          <ul className="benefits-list">
            {benefits.map((benefit, index) => (
              <li
                key={index}
                className={`benefit-item ${hoveredIndex === index ? 'active' : ''}`}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="benefit-box">
                  <div className="benefit-line"></div> {/* Palito naranja */}
                  <div>
                    <strong>{benefit.title}</strong> {benefit.description}
                  </div>
                  {hoveredIndex !== index && (
                    <div className="more-content-icon">Más</div>
                  )}
                </div>
                {hoveredIndex === index && (
                  <div className="extra-info">
                    {benefit.extraInfo}
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        <a href="#contacto" className="btn mt-6 rounded-lg">Solicita una consulta</a>
      </div>
    </section>
  );
}

export default Benefits;
