import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // Importa el archivo CSS que contiene los estilos personalizados
import App from './App'; // Importa el componente principal de la aplicación
import reportWebVitals from './reportWebVitals'; // Importa el módulo para medir el rendimiento

const root = ReactDOM.createRoot(document.getElementById('root'));

// Renderiza el componente principal de la aplicación dentro del contenedor con id 'root'
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Si quieres empezar a medir el rendimiento en tu aplicación, pasa una función
// para registrar los resultados (por ejemplo: reportWebVitals(console.log))
// o envíalos a un endpoint de análisis. Aprende más en: https://bit.ly/CRA-vitals
reportWebVitals();

