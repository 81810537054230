import React, { useEffect, useRef, useState } from 'react';
import './Blog.css';
import Modal from './Modal'; // Importa el Modal

function Blog() {
  const carouselRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [selectedBlog, setSelectedBlog] = useState(null);

  useEffect(() => {
    const scroll = () => {
      if (carouselRef.current && !isDragging) {
        carouselRef.current.scrollLeft += 1.5;
        if (carouselRef.current.scrollLeft >= carouselRef.current.scrollWidth - carouselRef.current.clientWidth) {
          carouselRef.current.scrollLeft = 0;
        }
      }
    };

    const intervalId = setInterval(scroll, 20);
    return () => clearInterval(intervalId);
  }, [isDragging]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    carouselRef.current.style.transition = 'none';
    setStartX(e.pageX - carouselRef.current.offsetLeft);
    setScrollLeft(carouselRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    carouselRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUpOrLeave = () => {
    setIsDragging(false);
    carouselRef.current.style.transition = 'scroll-left 0.5s ease-out';
  };

  const news = [
    {
      title: 'Cómo mejorar tu SEO en 2024',
      date: 'Octubre 25, 2024',
      description: 'Conoce las últimas estrategias de SEO para mejorar el ranking de tu página en los motores de búsqueda.',
      content: 'Este es el contenido completo del blog sobre cómo mejorar tu SEO en 2024.',
      image: 'https://via.placeholder.com/300'
    },
    {
      title: 'Tendencias de desarrollo web',
      date: 'Octubre 20, 2024',
      description: 'Descubre las tendencias más recientes en el mundo del desarrollo web y cómo aplicarlas.',
      content: 'Este es el contenido completo del blog sobre tendencias de desarrollo web.',
      image: 'https://via.placeholder.com/300'
    },
    {
      title: 'Estrategias de marketing digital',
      date: 'Octubre 15, 2024',
      description: 'Las estrategias más efectivas para aumentar la visibilidad de tu negocio en línea.',
      content: 'Este es el contenido completo del blog sobre estrategias de marketing digital.',
      image: 'https://via.placeholder.com/300'
    },
    {
      title: 'Automatización y eficiencia en 2024',
      date: 'Septiembre 28, 2024',
      description: 'La automatización de procesos es esencial para la eficiencia.',
      content: 'La automatización de procesos a través de herramientas como RPA es una tendencia clave. Aprende cómo mejorar tu flujo de trabajo con estas soluciones.',
      image: 'https://via.placeholder.com/300'
    },
    {
      title: 'Inteligencia Artificial y el futuro',
      date: 'Octubre 10, 2024',
      description: 'La IA está revolucionando el futuro de los negocios y la tecnología.',
      content: 'La IA y el machine learning son claves para la innovación en diversas industrias. Descubre cómo aplicarlas en tu negocio.',
      image: 'https://via.placeholder.com/300'
    },
    {
      title: 'Seguridad en aplicaciones web',
      date: 'Octubre 5, 2024',
      description: 'Protege tus aplicaciones web con las mejores prácticas de seguridad.',
      content: 'La seguridad web es crítica en el entorno digital actual. Implementa prácticas sólidas para evitar vulnerabilidades y proteger tu negocio.',
      image: 'https://via.placeholder.com/300'
    }
  ];

  return (
    <section id="blog" className="blog-section">
      <h2 className="blog-title">Últimas Noticias</h2>
      <div
        className="carousel-wrapper"
        ref={carouselRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUpOrLeave}
        onMouseLeave={handleMouseUpOrLeave}
      >
        <div className="carousel-container">
          {news.map((item, index) => (
            <div key={index} className="news-item">
              <h3>{item.title}</h3>
              <p>{item.date}</p>
              <p>{item.description}</p>
              <button
                className="read-more"
                onClick={() => setSelectedBlog(item)}
              >
                Leer más
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Modal con el contenido seleccionado */}
      {selectedBlog && (
        <Modal blog={selectedBlog} closeModal={() => setSelectedBlog(null)} />
      )}
    </section>
  );
}

export default Blog;
