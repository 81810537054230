import React, { useState } from 'react';
import logo from './assets/logo3.png';
import { Bars3Icon } from '@heroicons/react/24/outline';
import './Header.css';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <header className="header-container">
      <div className="header-content">
        <a href="#inicio" onClick={closeMenu} className="logo-container">
          <img src={logo} alt="Logo" className="logo-image" />
        </a>
        <a href="#inicio" onClick={closeMenu} className="header-title">The 18s Technologies</a> {/* Aquí se agregó el enlace al texto */}
        <button onClick={toggleMenu} className="hamburger-button md:hidden focus:outline-none">
          <Bars3Icon className="hamburger-icon" />
        </button>
        <nav className={`nav-links ${isOpen ? 'open' : ''}`}>
          <a href="#inicio" onClick={closeMenu}>Inicio</a>
          <a href="#servicios" onClick={closeMenu}>Servicios</a>
          <a href="#beneficios" onClick={closeMenu}>Beneficios</a>
          <a href="#portafolio" onClick={closeMenu}>Portafolio</a>
          <a href="#blog" onClick={closeMenu}>Blog</a> {/* Nueva pestaña de Blog */}
          <a href="#sobre-nosotros" onClick={closeMenu}>Sobre Nosotros</a>
          <a href="#testimonios" onClick={closeMenu}>Testimonios</a>
          <a href="#contacto" onClick={closeMenu}>Contacto</a>
        </nav>
      </div>
    </header>
  );
}

export default Header;
