import React from 'react';
import './Modal.css'; // Estilos del modal

// Importamos las imágenes desde src/assets/imagenes_blog/
import automatizacion from './assets/imagenes_blog/automatización.jpg';
import desarrolloWeb from './assets/imagenes_blog/desarrollo_web.jpg';
import inteligenciaArtificial from './assets/imagenes_blog/Inteligencia_Artificial.jpg';
import marketing from './assets/imagenes_blog/marketing.jpg';
import seguridad from './assets/imagenes_blog/seguridad.jpg';
import seo from './assets/imagenes_blog/seo.jpg';

function Modal({ blog, closeModal }) {
  // Creamos un objeto que asocia cada título de blog con su imagen
  const imageMap = {
    'Cómo mejorar tu SEO en 2024': seo,
    'Tendencias de desarrollo web': desarrolloWeb,
    'Estrategias de marketing digital': marketing,
    'Automatización y eficiencia en 2024': automatizacion,
    'Inteligencia Artificial y el futuro': inteligenciaArtificial,
    'Seguridad en aplicaciones web': seguridad
  };

  const blogImage = imageMap[blog.title]; // Obtener la imagen según el título del blog

  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-modal" onClick={closeModal}>
          &times;
        </button>
        {/* Título centrado y en color naranja tomate */}
        <h2 className="modal-title">{blog.title}</h2>
        <p className="modal-date">{blog.date}</p>
        {blogImage && <img src={blogImage} alt={blog.title} className="modal-image" />}
        <p>{blog.content}</p>

        {/* Contenido adicional para cada artículo con enlaces a otros blogs o foros */}
        {blog.title === 'Cómo mejorar tu SEO en 2024' && (
          <>
            <p>
              El SEO sigue evolucionando, y las estrategias de 2024 se centran en la búsqueda por voz, contenido de calidad y optimización para móviles. No te quedes atrás con las últimas tendencias.
            </p>
            <h3>Enlaces de interés:</h3>
            <ul className="external-links">
              <li>
                <a href="https://moz.com/blog" target="_blank" rel="noopener noreferrer">Moz Blog - Estrategias SEO Avanzadas</a>
              </li>
              <li>
                <a href="https://backlinko.com" target="_blank" rel="noopener noreferrer">Backlinko - Guía completa de SEO</a>
              </li>
              <li>
                <a href="https://neilpatel.com/es/blog/" target="_blank" rel="noopener noreferrer">Neil Patel Blog - Consejos SEO 2024</a>
              </li>
            </ul>
          </>
        )}
        {blog.title === 'Tendencias de desarrollo web' && (
          <>
            <p>
              En 2024, tecnologías como JAMstack, PWA y el uso intensivo de JavaScript seguirán siendo tendencias claves para el desarrollo web. Mantente actualizado con las últimas herramientas y frameworks.
            </p>
            <h3>Enlaces de interés:</h3>
            <ul className="external-links">
              <li>
                <a href="https://www.smashingmagazine.com" target="_blank" rel="noopener noreferrer">Smashing Magazine - Desarrollo Web</a>
              </li>
              <li>
                <a href="https://www.sitepoint.com" target="_blank" rel="noopener noreferrer">SitePoint - Artículos sobre Desarrollo Web</a>
              </li>
              <li>
                <a href="https://web.dev" target="_blank" rel="noopener noreferrer">Web.dev - Guía para desarrolladores</a>
              </li>
            </ul>
          </>
        )}
        {blog.title === 'Estrategias de marketing digital' && (
          <>
            <p>
              El marketing digital es clave para el éxito de cualquier negocio online. En 2024, las campañas personalizadas y la IA marcarán la diferencia en la captación de nuevos clientes.
            </p>
            <h3>Enlaces de interés:</h3>
            <ul className="external-links">
              <li>
                <a href="https://blog.hubspot.com" target="_blank" rel="noopener noreferrer">HubSpot Blog - Marketing Digital</a>
              </li>
              <li>
                <a href="https://www.marketingdirecto.com" target="_blank" rel="noopener noreferrer">MarketingDirecto.com - Noticias de Marketing</a>
              </li>
              <li>
                <a href="https://www.socialmediatoday.com" target="_blank" rel="noopener noreferrer">Social Media Today - Tendencias de Marketing</a>
              </li>
            </ul>
          </>
        )}
        {blog.title === 'Automatización y eficiencia en 2024' && (
          <>
            <p>
              La automatización de procesos con herramientas como RPA está revolucionando el trabajo empresarial, mejorando la eficiencia y reduciendo errores.
            </p>
            <h3>Enlaces de interés:</h3>
            <ul className="external-links">
              <li>
                <a href="https://www.uipath.com/blog" target="_blank" rel="noopener noreferrer">UiPath Blog - Automatización RPA</a>
              </li>
              <li>
                <a href="https://www.automationanywhere.com/blog" target="_blank" rel="noopener noreferrer">Automation Anywhere Blog</a>
              </li>
              <li>
                <a href="https://hbr.org/2021/01/using-automation-to-improve-work" target="_blank" rel="noopener noreferrer">Harvard Business Review - Automatización y Eficiencia</a>
              </li>
            </ul>
          </>
        )}
        {blog.title === 'Inteligencia Artificial y el futuro' && (
          <>
            <p>
              La IA está transformando industrias enteras. Desde la medicina hasta la atención al cliente, las aplicaciones de la inteligencia artificial están redefiniendo cómo vivimos y trabajamos.
            </p>
            <h3>Enlaces de interés:</h3>
            <ul className="external-links">
              <li>
                <a href="https://www.artificialintelligence-news.com" target="_blank" rel="noopener noreferrer">Artificial Intelligence News</a>
              </li>
              <li>
                <a href="https://towardsdatascience.com" target="_blank" rel="noopener noreferrer">Towards Data Science</a>
              </li>
              <li>
                <a href="https://futureoflife.org/ai/" target="_blank" rel="noopener noreferrer">Future of Life Institute - AI</a>
              </li>
            </ul>
          </>
        )}
        {blog.title === 'Seguridad en aplicaciones web' && (
          <>
            <p>
              La seguridad web es más importante que nunca en 2024. Proteger tus aplicaciones con herramientas avanzadas es esencial para evitar ciberataques y proteger los datos de tus usuarios.
            </p>
            <h3>Enlaces de interés:</h3>
            <ul className="external-links">
              <li>
                <a href="https://owasp.org" target="_blank" rel="noopener noreferrer">OWASP - Seguridad Web</a>
              </li>
              <li>
                <a href="https://www.csoonline.com" target="_blank" rel="noopener noreferrer">CSO Online - Ciberseguridad</a>
              </li>
              <li>
                <a href="https://krebsonsecurity.com" target="_blank" rel="noopener noreferrer">Krebs on Security</a>
              </li>
            </ul>
          </>
        )}

        <a href="https://www.18s.tech" target="_blank" rel="noopener noreferrer" className="external-link">
          Leer más artículos relacionados
        </a>
      </div>
    </div>
  );
}

export default Modal;
