import React, { useEffect, useState } from 'react';
import './TypingEffect.css';

const TypingEffect = ({ texts = [], speed = 50, delayBetweenTexts = 4000, delayBeforeRestart = 4000 }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    if (texts.length === 0) return;

    let index = 0;
    setIsFinished(false);

    const interval = setInterval(() => {
      setDisplayedText(texts[currentTextIndex].slice(0, index + 1));
      index++;
      if (index === texts[currentTextIndex].length) {
        clearInterval(interval);
        setIsFinished(true);
        if (currentTextIndex < texts.length - 1) {
          setTimeout(() => {
            setCurrentTextIndex(currentTextIndex + 1);
            setDisplayedText('');
            setIsFinished(false);
          }, delayBetweenTexts);
        } else {
          setTimeout(() => {
            setCurrentTextIndex(0); // Reinicia la animación
            setDisplayedText('');
            setIsFinished(false);
          }, delayBeforeRestart);
        }
      }
    }, speed);

    return () => clearInterval(interval);
  }, [currentTextIndex, texts, speed, delayBetweenTexts, delayBeforeRestart]);

  return (
    <span className="typing-effect">
      {displayedText}
      <span className={`blinking-cursor ${isFinished ? 'active' : ''}`}>|</span>
    </span>
  );
};

export default TypingEffect;
