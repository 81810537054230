document.addEventListener('DOMContentLoaded', function () {
    const header = document.querySelector('.header-container');
    
    // Verificamos que header no sea null
    if (header) {
        let lastScrollTop = 0;

        window.addEventListener('scroll', function () {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (scrollTop > lastScrollTop) {
                // Si se desplaza hacia abajo, oculta el header
                header.classList.add('hidden');
                header.classList.remove('visible');
            } else {
                // Si se desplaza hacia arriba, muestra el header
                header.classList.remove('hidden');
                header.classList.add('visible');
            }

            lastScrollTop = scrollTop;
        });
    } else {
        console.error("No se encontró el elemento con la clase 'header-container'");
    }
});
